<template>
  <BeginEvaluation :id="id" />
</template>

<script>
import { provide } from "vue";
// Components
import BeginEvaluation from "./BeginEvaluation";

export default {
  components: {
    BeginEvaluation
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  setup() {
    // Provide
    provide("evaluationType", "quizzes");
    provide("isConfirmLeavePageRequired", true);
  }
};
</script>
